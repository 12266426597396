<template>
    <div class="playlist-container">
        <iframe 
            :src="getPlaylistEmbedLink(playlistId)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="playlist-iframe">
        </iframe>
    </div>
</template>

<script>
export default {
    props: {
        playlistId: {
            type: String,
            required: true
        }
    },
    methods: {
        getPlaylistEmbedLink(playlistId) {
            return `https://www.youtube.com/embed?listType=playlist&list=${playlistId}&rel=0`;
        }
    }
}
</script>

<style scoped>
.playlist-container {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
}

.playlist-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>

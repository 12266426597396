<script lang="ts" setup>
defineProps<{
    showModal: boolean
}>()
const playlistId = ref('PLLEAvLyw7zpnBWVFO_dzrM4oENfBdddbD')
const emit = defineEmits<{
    'update:display': [display: boolean]
}>()

const closeModal = () => {
    emit('update:display', false)
}
</script>
<template>
    <Dialog
        :visible="showModal"
        @update:visible="closeModal"
        :modal="true"
        style="width: 50vw"
        header="How to use our product?"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <div class="p-3">
            <p class="custom-text-video">
                Explore further videos click on three lines
            </p>
            <i class="pi pi-youtube custom-styles" style="font-size: 27px"></i>
            <i
                class="pi pi-arrow-down custom-styles-arrow"
                style="font-size: 19px"
            ></i>
        </div>
        <YoutubeList :playlistId="playlistId"></YoutubeList>
        <template #footer>
            <div class="pt-3 flex justify-content-end">
                <Button
                    label="Close"
                    severity="danger"
                    raised
                    @click="closeModal"
                />
            </div>
        </template>
    </Dialog>
</template>

<style lang="scss">
.custom-styles {
    position: absolute;
    top: 1.6rem;
    right: 4.2rem;
}

.custom-styles-arrow {
    position: absolute;
    top: 4rem;
    right: 4.5rem;
}

.custom-text-video {
    position: absolute;
    top: 2rem;
    right: 7rem;
    color: black;
    font-weight: 500;
}
</style>
